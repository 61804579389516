<template>
  <LoadingModal :show="runningCommand" />
  <SystemInfo :system="system" />

  <div
    class="row mt-5 bg-light pb-4 text-center flex-grow-1"
    v-if="system && system.name && system.state != 40 && system.state != 50"
  >
    <div>
      <div class="btn-group mt-n4 mx-3" v-if="!system.readOnly">
        <button
          class="btn btn-primary"
          @click="
            command = 'on';
            openModal();
          "
          v-bind:disabled="runningCommand || system.pendingStartStopCommand"
          v-if="system.state == 0"
        >
          <span v-show="!system.pendingStartStopCommand">{{$t('button.start')}}</span>
          <span v-show="system.pendingStartStopCommand"
            ><i class="fas fa-spinner fa-spin"></i
          ></span>
        </button>
        <button
          class="btn btn-primary"
          @click="
            command = 'off';
            openModal();
          "
          v-bind:disabled="runningCommand || system.pendingStartStopCommand"
          v-if="system.state != 0"
        >
          <span v-show="!system.pendingStartStopCommand">{{$t('button.stop')}}</span>
          <span v-show="system.pendingStartStopCommand"
            ><i class="fas fa-spinner fa-spin"></i
          ></span>
        </button>
      </div>
      <div class="btn-group mt-n4 mx-3" v-if="!system.readOnly">
        <button
          class="btn btn-primary"
          @click="reset"
          v-bind:disabled="runningCommand"
        >
          {{$t('button.reset')}}
        </button>
      </div>

      <div class="row mt-4 justify-content-center">
        <div class="col-6" v-if="system.flowCv">
          <div class="box">
            <div class="box__value">
              <div class="box__value__title">{{$t('system.flow')}}</div>
              <div class="box__value__val">
                {{
                  $filters.number(
                    parseFloat(system.flowCv),
                    0,
                    " ",
                    system.updateDate
                  )
                }}
                {{ system.flowUnit }}
              </div>
            </div>
            <div class="box__value">
              <div class="box__value__title">{{$t('system.totalFlow')}}</div>
              <div class="box__value__val">
                {{
                  $filters.number(system.flowTotalCv, 0, " ", system.updateDate)
                }}
                m³
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="box">
            <div class="box__value">
              <div class="box__value__title">{{$t('system.currentPressure')}}</div>
              <div class="box__value__val">
                {{
                  $filters.number(system.pressureCv, 1, " ", system.updateDate)
                }}
                bar
              </div>
            </div>
            <div class="box__value" v-if="system.pressureSp">
              <div class="box__value__title">{{$t('system.desiredPressure')}}</div>
              <div class="box__value__val">
                {{
                  $filters.number(system.pressureSp, 1, " ", system.updateDate)
                }}
                bar
              </div>
            </div>
            <div class="box__action" v-if="system.pressureSp">
              <button
                type="button"
                class="btn btn-dark btn-sm btn-rounded"
                @click="
                  command = 'pressure';
                  openModal();
                "
                v-if="!system.readOnly"
              >
                {{$t('button.edit')}}
              </button>
            </div>
          </div>

          <CommandModal>
            <template #body>
              <div v-if="command == 'on'">{{$t('system.confirmationStartPumpStation')}}</div>
              <div v-if="command == 'off'">{{$t('system.confirmationStopPump')}}</div>              
              <div v-if="command == 'toggleSubPump'">
                <span v-if="commandObject.enabled"
                  >{{$t('system.questionDeactivatePump')}} {{ commandObject.number }}?</span
                >
                <span v-if="!commandObject.enabled"
                  >{{$t('system.questionActivatePump')}} {{ commandObject.number }}?</span
                >
              </div>
              <div v-if="command == 'pressure'">
                <div class="row">
                  <div class="col gx-0 text-end">
                    <i
                      class="fal fa-minus fa-lg"
                      @click="decrementPressure"
                    ></i>
                  </div>
                  <div class="col">
                    <input
                      type="number"
                      step=".1"
                      min="0"
                      max="1000"
                      v-model="pressure"
                      style="
                        width: 60px;
                        border: 0;
                        border-bottom: 1px solid;
                        text-align: center;
                      "
                    />
                  </div>
                  <div class="col gx-0 text-start">
                    <i class="fal fa-plus fa-lg" @click="incrementPressure"></i>
                  </div>
                </div>
              </div>
            </template>
            <template #action>
              <button
                type="button"
                class="btn btn-primary btn-sm btn-rounded"
                v-if="command == 'pressure'"
                @click="changePressure"
              >
                {{$t("system.editPressure")}}
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm btn-rounded"
                v-if="command == 'on'"
                @click="turnOn"
              >
                {{$t('button.start')}}
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm btn-rounded"
                v-if="command == 'off'"
                @click="turnOff"
              >
                {{$t('button.stop')}}
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm btn-rounded"
                v-if="command == 'toggleSubPump'"
                @click="
                  toggleSubPump(commandObject.number, commandObject.enabled)
                "
              >
                {{ commandObject.enabled ? $t('system.deactivate') : $t('system.activate') }}
              </button>
            </template>
          </CommandModal>
        </div>
      </div>

      <div class="mt-4">
        <div class="row">
          <div class="col-12">
            <div class="pump" v-for="pump in system.pumps" :key="pump.number">
              <!-- <div class="pump__number">Pump {{ pump.number }}</div> -->
              <div class="pump__label">
                <small
                  ><strong>{{$t('system.pump')}} {{ pump.number }}</strong></small
                >
              </div>
              <div class="pump__inner">
                <div class="pump__inner__switch">
                  <div class="toggle-btn">
                    <div v-show="pump.pendingCommand" class="text-center">
                      <i class="fas fa-spinner fa-spin fa-2x"></i>
                    </div>
                    <div
                      v-show="!pump.pendingCommand"
                      class="button b2"
                      id="button-13"
                      @click="openToggleSubPumpModal(pump)"
                    >
                      <input
                        type="checkbox"
                        class="checkbox"
                        v-model="pump.enabled"
                        style="display: none"
                      />
                      <div class="knobs">
                        <span></span>
                      </div>
                      <div class="layer"></div>
                    </div>
                  </div>
                </div>
                <div class="pump__inner__info">
                  {{ $filters.number(pump.powerCv, 1, " ", system.updateDate) }}
                  kW<br />
                  {{
                    $filters.number(pump.frequencyCv, 1, " ", system.updateDate)
                  }}
                  Hz
                </div>
                <div class="pump__inner__status">
                  <img
                    v-if="
                      pump.statusCode == 10 ||
                      pump.statusCode == 11 ||
                      pump.statusCode == 20
                    "
                    :src="require(`@/assets/images/pumps/pump-square.svg`)"
                  />
                  <img
                    v-if="
                      pump.statusCode == 12 ||
                      pump.statusCode == 13 ||
                      pump.statusCode == 14
                    "
                    :src="require(`@/assets/images/pumps/pump-warning.svg`)"
                  />
                  <img
                    v-if="pump.statusCode == 21"
                    :src="require(`@/assets/images/pumps/pump-hand.svg`)"
                  />
                  <img
                    v-if="pump.statusCode == 22"
                    :src="require(`@/assets/images/pumps/pump-check.svg`)"
                  />
                  <img
                    v-if="pump.statusCode == 23"
                    :src="require(`@/assets/images/pumps/pump-arrow.svg`)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <div class="row small">
          <div class="col-6" v-for="data in additionalData" :key="data">
            <div class="row g-1">
              <div class="col-4 text-end text-muted">{{ data.label }}</div>
              <div class="col-8">
                <strong>{{ data.value }} {{ data.unit }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <pre>{{ system }}</pre> -->
  <teleport to="#headerAction" v-if="system && system.name">
    <div>
      <router-link
        v-bind:to="
          '/projects/' + system.project.id + '/' + system.id + '/alarms'
        "
      >
        <img
          src="../../assets/images/icons/bell.svg"
          alt="bell"
          width="23"
          v-if="system.state == 30"
        />
        <i class="fas fa-bell fa-lg text-dark" v-else></i>
      </router-link>
    </div>
  </teleport>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import Systems from "../../api/systems";
import LoadingModal from "../LoadingModal";
import CommandModal from "../CommandModal";
import SystemInfo from "./Shared/SystemInfo";
import { Modal } from "bootstrap";
import i18n from '../../i18n';

export default {
  components: { SystemInfo, LoadingModal, CommandModal },
  props: ["currentSystem"],
  setup(props) {
    const system = ref(props.currentSystem);
    const pressure = ref(0);
    const command = ref();
    const commandObject = ref();
    const updateFreq = 14000;
    const runningCommand = ref(false);
    const route = useRoute();
    const { proxy } = getCurrentInstance();
    const $toast = proxy.$toast;
    const $filters = proxy.$filters;
    const systemId = route.params.systemId;
    let interval = "";
    let syncing = false;
    const modal = ref({});

    onMounted(() => {
      modal.value = new Modal(document.getElementById("commandModal"));

      // Clear interval first in case of any previous setup (optional safety).
      if (interval) {
        clearInterval(interval);
      }

      interval = setInterval(sync, updateFreq);
    });

    onUnmounted(() => {
      clearInterval(interval);
    });

    function openModal() {
      pressure.value = system.value.pressureSp;
      modal.value.show();
    }

    function decrementPressure() {
      pressure.value = (pressure.value - 0.1).toFixed(1);
    }

    function incrementPressure() {
      pressure.value = (parseFloat(pressure.value) + 0.1).toFixed(1);
    }

    function sync() {
      if(syncing) {
          return;
      }

      syncing = true;
      Systems.sync(systemId)
        .then((res) => {
          system.value = res.data;
          runningCommand.value = false;
          syncing = false;
        })
        .catch((error) => {
          console.log(error.response.data);
          syncing = false;
        });
    }

    function turnOn() {
      runningCommand.value = true;
      Systems.turnOn(systemId)
        .then(() => {
          $toast.success(i18n.global.t('system.pumpStarted'), {
            duration: 24000,
          });
          system.value.pendingStartStopCommand = true;
          runningCommand.value = false;
          modal.value.hide();
        })
        .catch((error) => {
          $toast.error(i18n.global.t('system.commandFailed') + ":<br>" + error.response.data);
          runningCommand.value = false;
        });
    }

    function turnOff() {
      runningCommand.value = true;
      Systems.turnOff(systemId)
        .then(() => {
          $toast.success(i18n.global.t('system.pumpTurnedOff'), {
            duration: 24000,
          });
          system.value.pendingStartStopCommand = true;
          runningCommand.value = false;
          modal.value.hide();
        })
        .catch((error) => {
          $toast.error(i18n.global.t('system.commandFailed') + ":<br>" + error.response.data);
          runningCommand.value = false;
        });
    }

    function reset() {
      runningCommand.value = true;
      Systems.reset(systemId)
        .then(() => {
          $toast.success(i18n.global.t('system.pumpRestored'), {
            duration: 24000,
          });
          runningCommand.value = false;
        })
        .catch((error) => {
          $toast.error(i18n.global.t('system.commandFailed') + ":<br>" + error.response.data);
          runningCommand.value = false;
        });
    }

    function changePressure() {
      runningCommand.value = true;
      Systems.changePressure(systemId, pressure.value)
        .then(() => {
          $toast.success(i18n.global.t('system.pressureUpdated'));
          system.value.pressureSp = pressure.value;
          runningCommand.value = false;
          modal.value.hide();
        })
        .catch((error) => {
          $toast.error(i18n.global.t('system.commandFailed') + ":<br>" + error.response.data);
          runningCommand.value = false;
        });
    }

    function openToggleSubPumpModal(pump) {
      command.value = "toggleSubPump";
      commandObject.value = pump;
      openModal();
    }

    function toggleSubPump(pumpNumber, currentState) {
      runningCommand.value = true;
      Systems.toggleSubPump(systemId, pumpNumber)
        .then(() => {
          $toast.success(
            "Pump " + (currentState == true ? i18n.global.t('system.deactivated') : i18n.global.t('system.activated'))
          );

          for (var i = 0; i < system.value.pumps.length; i++) {
            if (system.value.pumps[i].number == pumpNumber) {
              system.value.pumps[i].pendingCommand = true;
            }
          }

          runningCommand.value = false;
          modal.value.hide();
        })
        .catch((error) => {
          $toast.error(i18n.global.t('system.commandFailed') + ":<br>" + error.response.data);
          runningCommand.value = false;
        });
    }

    const additionalData = computed(() => {
      var data = [];

      if (system.value.energykWhTotal) {
        data.push({
          label: i18n.global.t('system.energy'),
          value: $filters.number(
            parseFloat(system.value.energykWhTotal),
            1,
            " ",
            system.value.updateDate
          ),
          unit: "kWh",
        });
      }
      if (system.value.stationRunHours) {
        data.push({
          label: i18n.global.t('system.operatingTime'),
          value: $filters.number(
            system.value.stationRunHours,
            0,
            " ",
            system.value.updateDate
          ),
          unit: "h",
        });
      }
      if (system.value.powerCv) {
        data.push({
          label: i18n.global.t('system.effect'),
          value: $filters.number(
            system.value.powerCv,
            1,
            " ",
            system.value.updateDate
          ),
          unit: "kW",
        });
      }

      if (system.value.temp3) {
        data.push({
          label: i18n.global.t('system.temp'),
          value: $filters.number(
            system.value.temp3,
            1,
            " ",
            system.value.updateDate
          ),
          unit: "°C",
        });
      }

      if (system.value.level1) {
        data.push({
          label: i18n.global.t('system.level'),
          value: $filters.number(
            parseFloat(system.value.level1) / 1000,
            3,
            " ",
            system.value.updateDate
          ),
          unit: "m",
        });
      }

      return data;
    });

    return {
      system,
      additionalData,
      turnOn,
      turnOff,
      reset,
      runningCommand,
      pressure,
      openModal,
      decrementPressure,
      incrementPressure,
      changePressure,
      command,
      commandObject,
      openToggleSubPumpModal,
      toggleSubPump,
    };
  },
};
</script>
<style>
</style>